import { graphql } from "gatsby";
import RatesComparison from "../../templates/ratesComparison";

export default function RatesComparisonPage({
	data: {
		craft: { entry },
	},
}) {
	return (
		<RatesComparison
			entry={entry}
			breadcrumb={[
				{
					url: "/home-loans",
					text: "Home Loans",
					type: "entry",
				},
				{
					text: "Rates",
				},
			]}
		/>
	);
}

export const pageQuery = graphql`
	query ratesComparison {
		craft {
			entry(slug: "rates-comparison") {
				... on CraftCMS_ratesComparison_ratesComparison_Entry {
					heroTitle
					heroText
					heroCtaFirst {
						element {
							slug
							uri
						}
						ariaLabel
						customText
						target
						text
						title
						type
						url
					}
					ratesCards {
						... on CraftCMS_ratesCards_card_BlockType {
							id
							cardRate
							cardRateSubtext
							cardText
							cardLabel
							cardLink {
								element {
									slug
									uri
								}
								ariaLabel
								customText
								target
								text
								title
								type
								url
							}
							cardLogo {
								width
								height
								url
								title
								imagerX(transform: "maxWidth-1920") {
									width
									height
									url
								}
								imagerXWp: imagerX(transform: "maxWidth-1920-wp") {
									width
									height
									url
								}
							}
						}
					}
					ratesComparisonTitle
					ratesComparisonTable {
						... on CraftCMS_ratesComparisonTable_ratestablerow_BlockType {
							ratestablerowlender
							ratestablerowvariablefloating
							ratestablerowfixed1year
							ratestablerowfixed2years
						}
					}
					ratesComparisonSmallPrint

					toolsRowItems {
						... on CraftCMS_toolTiles_toolTiles_Entry {
							toolTileIcon
							toolTileTitle
							toolTileText
							toolTileLink {
								element {
									slug
									uri
								}
								ariaLabel
								customText
								target
								text
								title
								type
								url
							}
						}
					}
					footerBannerTitle
					footerBannerText

					footerBannerButton {
						element {
							slug
							uri
						}
						ariaLabel
						customText
						target
						text
						title
						type
						url
					}
					metaTitle
					metaDescription
					metaShareImage {
						filename
						url
					}
				}
			}
		}
	}
`;
